<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="flex flex-col">
        <div class="flex justify-between items-center w-10/12 mx-auto">
          <div class="border-2 border-green-1002 flex px-6 py-3 rounded-lg">
            <router-link :to="{ name: 'search-participants' }">
              Voltar
            </router-link>
          </div>
          <div class="border-2 border-green-1002 flex px-6 py-3 rounded-lg">
            <router-link
              :to="{ name: 'curriculum', params: { id: this.id } }"
              target="_blank"
            >
              Visualizar Currículo
            </router-link>
          </div>
          <div class="border-2 border-green-1002 flex px-6 py-3 rounded-lg">
            <router-link
              :to="{
                name: 'participant-curriculum',
                params: { participant: this.id }
              }"
            >
              Editar Currículo Complementar
            </router-link>
          </div>
        </div>
        <div class="w-10/12 mx-auto">
          <form-wizard
            @on-complete="onComplete"
            :start-index="0"
            color="#69CA5E"
            title=""
            subtitle=""
            ref="form"
            next-button-text="Próximo"
            back-button-text="Anterior"
            finish-button-text="Finalizar"
          >
            <tab-content title="Identificação" icon="ti-user">
              <div
                class="w-12/12 border border-green-600  rounded-lg py-3 px-5 mx-auto mt-5"
              >
                <div class="flex w-full mb-5">
                  <div class="flex w-6/12 flex-col mr-5">
                    <label class="mb-2" for="name">Nome</label>
                    <input
                      v-model="$v.participant.name.$model"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none cursor-not-allowed"
                      id="name"
                      type="text"
                      placeholder="Nome do Associado"
                      autocomplete="off"
                      readonly
                    />
                    <div v-if="$v.participant.name.$error">
                      <div class="error" v-if="!$v.participant.name.required">
                        Campo obrigatório.
                      </div>
                      <div
                        class="error"
                        v-else-if="!$v.participant.name.maxLength"
                      >
                        O nome do talento deve ter no máximo
                        {{ $v.associate.name.$params.maxLength.max }}
                        caracteres.
                      </div>
                    </div>
                  </div>

                  <div class="flex w-6/12 flex-col mr-5">
                    <label class="mb-2" for="email">E-mail</label>
                    <input
                      v-model="$v.participant.email.$model"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="email"
                      type="text"
                      placeholder="E-mail"
                      autocomplete="off"
                    />
                    <div v-if="$v.participant.email.$error">
                      <div class="error" v-if="!$v.participant.email.required">
                        Campo obrigatório.
                      </div>
                      <div
                        class="error"
                        v-else-if="!$v.participant.email.maxLength"
                      >
                        O email do talento deve ter no máximo
                        {{ $v.participant.name.$params.maxLength.max }}
                        caracteres.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex w-full mb-5">
                  <div class="flex w-6/12 flex-col mr-5">
                    <label class="mb-2">CPF</label>
                    <input
                      v-model="$v.participant.document.$model"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none cursor-not-allowed"
                      type="text"
                      placeholder="CPF"
                      v-mask="'###.###.###-##'"
                      autocomplete="off"
                      readonly
                    />
                    <div v-if="$v.participant.document.$error">
                      <div
                        class="error"
                        v-if="!$v.participant.document.required"
                      >
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>

                  <div class="flex w-6/12 flex-col mr-5">
                    <label class="mb-2" for="education-level"
                      >Escolaridade</label
                    >
                    <multiselect
                      id="education-level"
                      v-model="$v.participant.educationLevels.selected.$model"
                      deselect-label="Remover"
                      track-by="name"
                      label="name"
                      select-label="Selecionar"
                      selected-label="Selecionado"
                      placeholder="Selecione uma escolaridade"
                      :options="participant.educationLevels.options"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.name }}
                      </template>
                      <span slot="noOptions"
                        >Nenhuma escolaridade encontrada.</span
                      >
                    </multiselect>
                    <div v-if="$v.participant.educationLevels.selected.$error">
                      <div
                        class="error"
                        v-if="!$v.participant.educationLevels.selected.required"
                      >
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex w-full mb-5">
                  <div class="flex w-3/12 flex-col mr-5">
                    <label class="mb-2" for="telephone">Telefone fixo</label>
                    <the-mask
                      v-model="participant.telephone"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="telephone"
                      type="text"
                      placeholder="Telefone"
                      mask="(##) ####-####"
                      :tokens="anonymousTokens"
                      autocomplete="off"
                    />
                  </div>

                  <div class="flex w-3/12 flex-col mr-5">
                    <label class="mb-2" for="cellphone">Telefone celular</label>
                    <the-mask
                      v-model="participant.cellphone"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="cellphone"
                      type="text"
                      placeholder="Celular"
                      :mask="['(##) ####-####', '(##) #####-####']"
                      :tokens="anonymousTokens"
                      autocomplete="off"
                    />
                  </div>

                  <div class="flex w-3/12 flex-col mr-5">
                    <label class="mb-2" for="available"
                      >Disponível para colheita?</label
                    >
                    <multiselect
                      id="available"
                      v-model="$v.participant.available.selected.$model"
                      deselect-label="Remover"
                      track-by="label"
                      label="label"
                      select-label="Selecionar"
                      selected-label="Selecionado"
                      placeholder="Selecione"
                      :options="participant.available.options"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.label }}
                      </template>
                      <span slot="noOptions">Nenhum registro encontrado.</span>
                    </multiselect>
                    <div v-if="$v.participant.available.selected.$error">
                      <div
                        class="error"
                        v-if="!$v.participant.available.selected.required"
                      >
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>

                  <div class="flex w-3/12 flex-col mr-5">
                    <label class="mb-2" for="status">Status</label>
                    <multiselect
                      id="status"
                      v-model="$v.participant.status.selected.$model"
                      deselect-label="Remover"
                      track-by="label"
                      label="label"
                      select-label="Selecionar"
                      selected-label="Selecionado"
                      placeholder="Selecione"
                      :options="participant.status.options"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.label }}
                      </template>
                      <span slot="noOptions">Nenhum registro encontrado.</span>
                    </multiselect>
                    <div v-if="$v.participant.status.selected.$error">
                      <div
                        class="error"
                        v-if="!$v.participant.status.selected.required"
                      >
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex w-full mb-5">
                  <div class="flex w-6/12 flex-col mr-5">
                    <label class="mb-2" for="gender">Sexo</label>
                    <multiselect
                      id="gender"
                      v-model="$v.participant.gender.selected.$model"
                      deselect-label="Remover"
                      track-by="label"
                      label="label"
                      select-label="Selecionar"
                      selected-label="Selecionado"
                      placeholder="Selecione"
                      :options="participant.gender.options"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.label }}
                      </template>
                      <span slot="noOptions">Nenhum registro encontrado.</span>
                    </multiselect>
                    <div v-if="$v.participant.gender.selected.$error">
                      <div
                        class="error"
                        v-if="!$v.participant.gender.selected.required"
                      >
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>

                  <div class="flex w-6/12 flex-col mr-5">
                    <label class="mb-2" for="birth-date"
                      >Data de Nascimento</label
                    >
                    <input
                      v-model="$v.participant.birthDate.$model"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="birth-date"
                      type="text"
                      placeholder="Data de Nascimento"
                      v-mask="'##/##/####'"
                    />
                    <div v-if="$v.participant.birthDate.$error">
                      <div
                        class="error"
                        v-if="!$v.participant.birthDate.required"
                      >
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex w-full mb-5">
                  <div class="flex w-6/12 flex-col mr-5">
                    <span>
                      <input
                        id="notifications"
                        type="checkbox"
                        v-model="notifications"
                      />
                      Receber notificações?
                    </span>
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content title="Endereço" icon="ti-location-pin">
              <div
                class="w-12/12 border border-green-600  rounded-lg py-3 px-5 mx-auto mt-5"
              >
                <div class="flex w-full mb-5">
                  <div class="flex w-3/12 flex-col mr-5">
                    <label class="mb-2" for="cep">CEP</label>
                    <input
                      id="cep"
                      type="text"
                      v-model="address.cep"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      placeholder="CEP"
                      v-mask="'#####-###'"
                    />
                  </div>

                  <div class="flex w-7/12 flex-col mr-5">
                    <label class="mb-2" for="address">Endereço</label>
                    <input
                      id="address"
                      type="text"
                      v-model="address.address"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      placeholder="Endereço"
                    />
                  </div>

                  <div class="flex w-2/12 flex-col mr-5">
                    <label class="mb-2" for="number">Número</label>
                    <input
                      id="number"
                      type="text"
                      v-model="address.number"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      placeholder="Número"
                    />
                  </div>
                </div>

                <div class="flex w-full mb-5">
                  <div class="flex w-6/12 flex-col mr-5">
                    <label class="mb-2" for="complement">Complemento</label>
                    <input
                      id="complement"
                      type="text"
                      v-model="address.complement"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      placeholder="Complemento"
                    />
                  </div>

                  <div class="flex w-6/12 flex-col mr-5">
                    <label class="mb-2" for="neighborhood">Bairro</label>
                    <input
                      id="neighborhood"
                      type="text"
                      v-model="address.neighborhood"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      placeholder="Bairro"
                    />
                  </div>
                </div>

                <div class="flex w-full mb-5">
                  <div class="flex w-6/12 flex-col mr-5">
                    <label class="mb-2" for="state">Estado</label>
                    <multiselect
                      id="state"
                      v-model="address.states.selected"
                      deselect-label="Remover"
                      track-by="name"
                      label="name"
                      select-label="Selecionar"
                      selected-label="Selecionado"
                      placeholder="Selecione um estado"
                      :options="address.states.options"
                      @select="fetchCities"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.name }}
                      </template>
                      <span slot="noOptions">Nenhum estado encontrado.</span>
                    </multiselect>
                  </div>

                  <div class="flex w-6/12 flex-col mr-5">
                    <label class="mb-2" for="city">Cidade</label>
                    <multiselect
                      id="city"
                      v-model="$v.address.cities.selected.$model"
                      deselect-label="Remover"
                      track-by="name"
                      label="name"
                      select-label="Selecionar"
                      selected-label="Selecionado"
                      placeholder="Selecione uma cidade"
                      :options="address.cities.options"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.name }}
                      </template>
                      <span slot="noOptions">Nenhuma cidade encontrada.</span>
                    </multiselect>
                    <div v-if="$v.address.cities.selected.$error">
                      <div
                        class="error"
                        v-if="!$v.address.cities.selected.required"
                      >
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout';
import { TheMask, mask } from 'vue-the-mask';
import { maxLength, required } from 'vuelidate/lib/validators';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'EditParticipant',

  title() {
    return `${process.env.VUE_APP_NAME} | Editar Talento`;
  },

  components: {
    DashboardLayout,
    TheMask,
    LoaderAnimation
  },

  directives: { mask },

  props: {
    id: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      notifications: false,
      participant: {
        userId: null,
        name: '',
        email: '',
        document: '',
        telephone: '',
        cellphone: '',
        birthDate: '',
        educationLevels: {
          options: [],
          selected: null
        },
        available: {
          options: [
            { label: 'Disponível', value: 1 },
            { label: 'Indisponível', value: 0 }
          ],
          selected: null
        },
        status: {
          options: [
            { label: 'Ativo', value: 1 },
            { label: 'Inativo', value: 0 }
          ],
          selected: null
        },
        gender: {
          options: [
            { label: 'Masculino', value: 'M' },
            { label: 'Feminino', value: 'F' }
          ],
          selected: null
        }
      },
      address: {
        cep: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        country: '',
        states: {
          options: [],
          selected: null
        },
        cities: {
          options: [],
          selected: null
        }
      },
      anonymousTokens: {
        '#': { pattern: /[\d*]/ }
      }
    };
  },

  validations: {
    participant: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      email: {
        required,
        maxLength: maxLength(100)
      },
      document: { required },
      educationLevels: {
        selected: { required }
      },
      available: {
        selected: { required }
      },
      status: {
        selected: { required }
      },
      gender: {
        selected: { required }
      },
      birthDate: { required }
    },
    address: {
      cities: {
        selected: { required }
      }
    }
  },

  methods: {
    onComplete() {
      if (this.$v.participant.$invalid) {
        this.$refs.form.changeTab(1, 0);

        this.$v.participant.name.$touch();
        this.$v.participant.email.$touch();
        this.$v.participant.document.$touch();
        this.$v.participant.birthDate.$touch();
        this.$v.participant.status.selected.$touch();
        this.$v.participant.available.selected.$touch();
        this.$v.participant.educationLevels.selected.$touch();
        this.$v.participant.gender.selected.$touch();
      } else if (this.$v.address.$invalid) {
        this.$v.address.cities.selected.$touch();
      } else {
        const participantAttributes = {
          document: this.participant.document,
          telephone: this.participant.telephone,
          cellphone: this.participant.cellphone,
          birth_date: this.participant.birthDate,
          gender: this.participant.gender.selected.value,
          status: this.participant.status.selected.value,
          available: this.participant.available.selected.value,
          education_level_id: this.participant.educationLevels.selected.id
        };

        const addressAttributes = {
          city_id: this.address.cities.selected.id,
          cep: this.address.cep,
          address: this.address.address,
          number: this.address.number,
          complement: this.address.complement,
          neighborhood: this.address.neighborhood
        };

        const userAttributes = {
          name: this.participant.name,
          email: this.participant.email
        };

        axios
          .patch(`/api/participantes/${this.id}`, participantAttributes)
          .then(({ data }) => {
            this.$toast.success(data.data.message);

            axios
              .patch(
                `/api/participantes/${this.id}/endereco`,
                addressAttributes
              )
              .then(({ data }) => {
                this.$toast.success(data.data.message);
              })
              .catch(({ response }) => {
                Object.values(response.data.errors).forEach(error => {
                  this.$toast.error(...error);
                });
              });

            if (this.notifications === true) {
              axios.post(
                `/api/subscription/subscribe/${this.participant.userId}`
              );
            } else {
              axios.post(
                `/api/subscription/unsubscribe/${this.participant.userId}`
              );
            }

            axios
              .patch(
                `/api/participantes/${this.id}/usuario/${this.participant.userId}`,
                userAttributes
              )
              .then(({ data }) => {
                this.$toast.success(data.data.message);
                setTimeout(() => {
                  this.$router.push({ name: 'search-participants' });
                }, 2000);
              })
              .catch(({ response }) => {
                Object.values(response.data.errors).forEach(error => {
                  this.$toast.error(...error);
                });
              });
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          });
      }
    },

    async fetchParticipant() {
      this.isLoading = true;
      const participant = await axios.get(`/api/participantes/${this.id}`);
      const address = await axios.get(`/api/participantes/${this.id}/endereco`);

      this.participant.userId = participant.data.data.userId;
      this.participant.educationLevels.selected =
        participant.data.data.educationLevel;
      this.participant.name = participant.data.data.name;
      this.participant.email = participant.data.data.email;
      this.participant.document = participant.data.data.document;
      this.participant.telephone = participant.data.data.telephone;
      this.participant.cellphone = participant.data.data.cellphone;
      this.participant.birthDate = participant.data.data.birthDate;
      this.participant.gender.selected = participant.data.data.gender;
      this.participant.available.selected = participant.data.data.available;
      this.participant.status.selected = participant.data.data.status;

      this.address.address = address.data.data.address;
      this.address.cep = address.data.data.cep;
      this.address.number = address.data.data.number;
      this.address.neighborhood = address.data.data.neighborhood;
      this.address.complement = address.data.data.complement;
      this.address.states.selected = address.data.data.state;

      if (this.address.states.selected) {
        await this.fetchCities(this.address.states.selected);

        this.address.cities.selected = address.data.data.city;
      }

      const notifications = await axios.get(
        `/api/subscription/${this.participant.userId}`
      );
      if (notifications.data.data != null) {
        this.notifications = notifications.data.data.accept;
        this.isLoading = false;
      } else {
        this.notifications = true;
        this.isLoading = false;
      }
    },

    goBack() {
      this.$router.go(-1);
    },

    async fetchStates() {
      await axios.get('/api/estados').then(({ data }) => {
        data.data.forEach(state =>
          this.address.states.options.push({
            id: state.id,
            name: state.name
          })
        );
      });
    },

    async fetchEducationLevels() {
      await axios.get('/api/escolaridades').then(({ data }) => {
        data.data.forEach(educationLevel =>
          this.participant.educationLevels.options.push({
            id: educationLevel.id,
            name: educationLevel.name
          })
        );
      });
    },

    async fetchCities(state) {
      this.address.cities.options = [];
      this.address.cities.selected = null;

      await axios.get(`/api/estados/${state.id}/cidades`).then(({ data }) => {
        data.data.forEach(city =>
          this.address.cities.options.push({
            id: city.id,
            name: city.name
          })
        );
      });
    }
  },
  created() {
    this.fetchStates();
    this.fetchEducationLevels();
    this.fetchParticipant();
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
